import products from '@/scripts/products.js';

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const ksYears = { foundations: ['themes'], home: ['', 'themes', 'themes', 'funs', 'funs', 'funs', 'funs'] };
const isLive = location.hostname === 'app.realpe.co.uk';

const unitFocus = {
  pe: [
    {
      1: ['Footwork', 'One Leg Balance'],
      2: ['Jumping and Landing', 'Seated Balance'],
      3: ['Dynamic Balance', 'Stance'],
      4: ['Ball Skills', 'Counter Balance'],
      5: ['Sending and Receiving', 'Reaction / Response'],
      6: ['Ball Chasing', 'Floor Work'],
    },
    {
      1: ['Footwork', 'One Leg Balance'],
      2: ['Jumping and Landing', 'Seated Balance'],
      3: ['Dynamic Balance', 'Stance'],
      4: ['Ball Skills', 'Counter Balance'],
      5: ['Sending and Receiving', 'Reaction / Response'],
      6: ['Ball Chasing', 'Floor Work'],
    },
    {
      1: ['Footwork', 'One Leg Balance'],
      2: ['Jumping and Landing', 'Seated Balance'],
      3: ['Dynamic Balance', 'Stance'],
      4: ['Ball Skills', 'Counter Balance'],
      5: ['Sending and Receiving', 'Reaction / Response'],
      6: ['Ball Chasing', 'Floor Work'],
    },
    {
      1: ['Footwork', 'One Leg Balance'],
      2: ['Jumping and Landing', 'Seated Balance'],
      3: ['Dynamic Balance', 'Ball Skills'],
      4: ['Sending and Receiving', 'Counter Balance'],
      5: ['Reaction / Response', 'Floor Work'],
      6: ['Ball Chasing', 'Stance'],
    },
    {
      1: ['Footwork', 'One Leg Balance'],
      2: ['Jumping and Landing', 'Seated Balance'],
      3: ['Dynamic Balance', 'Ball Skills'],
      4: ['Sending and Receiving', 'Counter Balance'],
      5: ['Reaction / Response', 'Floor Work'],
      6: ['Ball Chasing', 'Stance'],
    },
    {
      1: ['Ball Skills', 'Reaction / Response'],
      2: ['Dynamic Balance', 'Counter Balance'],
      3: ['Stance', 'Footwork'],
      4: ['Seated Balance', 'Floor Work'],
      5: ['Jumping and Landing', 'One Leg Balance'],
      6: ['Sending and Receiving', 'Ball Chasing'],
    },
    {
      1: ['Ball Skills', 'Reaction / Response'],
      2: ['Dynamic Balance', 'Counter Balance'],
      3: ['Stance', 'Footwork'],
      4: ['Seated Balance', 'Floor Work'],
      5: ['Jumping and Landing', 'One Leg Balance'],
      6: ['Sending and Receiving', 'Ball Chasing'],
    },
  ],
  gym: [
    { 1: ['Shape', 'Travel'], 2: ['Flight', 'Rotation'] },
    { 1: ['Shape', 'Travel'], 2: ['Flight', 'Rotation'] },
    { 1: ['Balance', 'Travel'], 2: ['Flight', 'Rotation'] },
    { 1: ['Travel', 'Rotation'], 2: ['Flight', 'Balance'] },
    { 1: ['Balance', 'Rotation'], 2: ['Flight', 'Travel'] },
    { 1: ['Hand Apparatus', 'Low Apparatus'], 2: ['Partner Work', 'Large Apparatus'] },
  ],
  dance: [
    { 1: ['Shapes, Partnering, Artistry, Circles'] },
    { 1: ['Shapes, Artistry, Partnering, Circles'] },
    { 1: ['Shapes, Partnering, Circles, Artistry'] },
    { 1: ['Shapes, Circles, Partnering, Artistry'] },
    { 1: ['Shapes, Circles, Partnering, Artistry'] },
    { 1: ['Shapes, Circles, Artistry, Partnering'] },
  ],
};

const unitSplit = {
  pe: {
    5: {
      1: { list: ['Invasion', 'Net & Wall'], live: true },
      2: { list: ['OAA', 'Invasion/OAA'], live: true },
      3: { list: ['Athletics', 'Invasion'], live: false },
      4: { list: ['Invasion', 'Net & Wall (Disability)'], live: true },
      5: { list: ['Athletics', 'Gymnastics'], live: true },
      6: { list: ['Striking & Fielding', 'Target / OAA'], live: true },
    },
    6: {
      1: { list: ['Invasion', 'Net & Wall'], live: true },
      2: { list: ['OAA', 'Invasion'], live: true },
      3: { list: ['Athletics', 'Invasion'], live: false },
      4: { list: ['Net & Wall (Disability)', 'OAA/Invasion'], live: true },
      5: { list: ['Athletics/Invasion', 'Gymnastics'], live: true },
      6: { list: ['Target / OAA', 'Striking & Fielding'], live: true },
    },
  },
};

export default {
  namespaced: true,

  state: {
    open: false,
    crumbs: [],
    current: {},
  },

  mutations: {
    toggle(state, action = null) {
      state.open = action === null ? !state.open : action;
    },
    crumb(state, data) {
      state.crumbs = data.filter(crumb => crumb);
    },
    setCurrent(state, product) {
      if (product.children) state.current = product;
    },
  },

  getters: {
    menu: (state, getters, { user }, rootGetters) => filterMenu(user, rootGetters),
    crumbs: (state, getters, { route }) => contentCrumbs(route.params, getters),
    current: (state, getters, { user }) => (user.kind === 'home' ? getters.menu[0] : state.current),
    crumbsTitle: (state, getters) => getters.crumbs.map(crumb => crumb.title),
  },

  actions: {
    select({ commit, getters }, { slug, select }) {
      const product = getters.menu.find(p => p.slug === slug);
      if (!product) return;
      product.select = (ksYears[slug] || [])[select] || select;
      commit('toggle', true);
      commit('setCurrent', product);
    },
  },
};

const baseMenu = Object.values(products())
  .filter(p => p.tree)
  .map(product => ({ ...product, uri: `/${product.slug}` }))
  .map(product => ({
    title: product.title,
    title2: product.title2,
    slug: product.slug,
    type: 'product',
    children: deeper(product, product.tree, product.uri) || [],
    abilitySelect: !!product.abilitySelect,
    ...(product.index && { uri: product.uri }),
  }));

function deeper(product, tree, uriBase, depth = 0, parentSlug = null) {
  const type = tree[depth] === 'item' ? parentSlug : tree[depth];
  let list = product[type] || [];

  return list.map(({ slug, title }) => {
    const noUriSlug = ['stage', 'themes', 'funs'].includes(type);
    const uri = `${uriBase}${!noUriSlug ? `/${type}` : ''}/${slug}`;

    let entry = { title, slug, type, uri };

    if (tree.length > depth + 1) {
      entry.children = deeper(product, tree, uri, depth + 1, slug);
    }

    if (type === 'unit') {
      if (product.ability) entry.ability = product.ability[slug - 1];
      entry.cmsSlug = `y${parentSlug !== 'club' ? parentSlug : ''}-u${slug}`;
      entry.focus = unitFocus[product.slug]?.[parentSlug]?.[slug];
      const split = unitSplit[product.slug]?.[parentSlug]?.[slug];

      if (split && (split.live || !isLive)) {
        entry.uri = null;
        // entry.cmsSlug = entry.cmsSlug + 'a';
        entry.split = split.list.map((label, i) => ({
          ...entry,
          title: `Unit ${slug}${letters[i]}`,
          slug: `${slug}${letters[i]}`,
          uri: `${uri}${letters[i]}`,
          letter: letters[i],
          label,
        }));
      }
    }

    return entry;
  });
}

function filterMenu({ kind }, getters) {
  if (kind === 'create') return baseMenu; // create has everything
  if (kind === 'home') return baseMenu.filter(({ slug }) => slug === 'home').map(p => ({ ...p, title: p.title2 })); // home has home

  return baseMenu.map(product => ({
    ...product,
    children: product.children.filter(child => getters['user/hasSection'](product.slug, child.slug)),
  }));
}

const contentParams = ['product', 'year', 'stage', 'unit', 'lesson', 'item'];

const contentCrumbs = (params, getters) =>
  Object.keys(params)
    .filter(param => contentParams.includes(param))
    .reduce((carry, param) => {
      const prev = carry[carry.length - 1];
      let source = prev ? prev.children : getters.menu;

      if (prev?.type === 'year') {
        // look for split units if they exist
        source = prev.children.reduce((carry, unit) => [...carry, ...(unit.split ? unit.split : [unit])], []);
      }
      const entry = (source || []).find(entry => entry.slug == params[param]);

      if (entry) carry.push(entry);

      return carry;
    }, [])
    .map(({ children, ...crumb }) => crumb);
